import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import avatar from "../assets/img/avatars/avatar.jpg"
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function Laws() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
  ];

  const editorStyle = {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '20px',
  };

  const formRef = useRef();

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedEnglishPdf, setSelectedEnglishPdf] = useState(null);

  const [selectedFrenchPdf, setSelectedFrenchPdf] = useState(null);

  const [fileurl,setFileUrl] = useState(null);

  const [fileurlEnglishPdf,setFileUrlEnglishPdf] = useState(null);

  const [fileurlFrenchPdf,setFileUrlFrenchPdf] = useState(null);

  const [selectedLawId, setSelectedLawId] = useState(null);

  const [editorDescription, setEditorDescription] = useState('');

  const [laws, setlaws] = useState([]);

  const [datalaw,setDataLaw] = useState({});

  const inputlaws = useRef([]);

  const [categories, setcategories] = useState([]);

  const [loadingcategory, setLoadingCategory] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);

  const [errors,setErrors] = useState({});

  const [success,setSucces] = useState(""); 

  const addInputsLaw = el => {
    if (el && !inputlaws.current.includes(el)) {
      inputlaws.current.push(el)
    }
  }

  const handleModalClose = () => {
    setShowModal(false);
    setErrors({});
    setSelectedLawId(null);
    inputlaws.current = [];
    setEditorDescription("");
    setSelectedImage(null);
  };

  const handleEditoroDescriptionChange = (html) => {
    setEditorDescription(html);
  };

  const handleModalOpen = async (id) => {
    if (id != "") {
      setSelectedLawId(id);
      await axiosClient.get(`/law/${id}`).then(({data}) => {
        let list = data.data;
        setDataLaw(list);
        setEditorDescription(list.law_description);
      });
    }
    inputlaws.current = [];   
    setShowModal(true);
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
      setFileUrl(URL.createObjectURL(file));
    } else {
      Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier image valide.',showConfirmButton: true});
    }
  };

  const handleEnglishPdfUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('application/')) {
      setSelectedEnglishPdf(file);
      setFileUrlEnglishPdf(URL.createObjectURL(file));
    } else {
      Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier pdf valide.',showConfirmButton: true});
    }
  };


  const handleFrenchPdfUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('application/')) {
      setSelectedFrenchPdf(file);
      setFileUrlFrenchPdf(URL.createObjectURL(file));
    } else {
      Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier pdf valide.',showConfirmButton: true});
    }
  };

  const addUpdateLaw = async(event) => {
    event.preventDefault();
    const errors = {};

    if (inputlaws.current[0].value.trim() == '') {
      errors.law_title = 'The name field is required.';
    }        

    if (!editorDescription || editorDescription == '<p><br></p>') {
      errors.law_description = 'The description field is required.';
    }   
    
    if (!selectedLawId) {
      if (!fileurl) {
        errors.law_img = 'This image is required.';
      } 
      if (!fileurlEnglishPdf) {
        errors.law_englishpdf = 'This english pdf is required.';
      } 
      if (!fileurlFrenchPdf) {
        errors.law_frenchpdf = 'This french pdf is required.';
      }              
    }  

    if (Object.keys(errors).length === 0) {
      setLoadingSubmitButton(true);
      if (!selectedLawId) {
        const lawData = new FormData();
        lawData.append('title', inputlaws.current[0].value.trim());
        lawData.append('description', editorDescription);
        lawData.append('category_id', inputlaws.current[1].value.trim());
        lawData.append('img', selectedImage);
        lawData.append('pdf_english', selectedEnglishPdf);
        lawData.append('pdf_french', selectedFrenchPdf);
        await axiosClient.post('/laws',lawData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Law added success',showConfirmButton: true});
          setSucces("Law added success !!");
          setErrors(errors);
          setTimeout(() => { setSucces('');}, 3000);
          formRef.current.reset();
          setSelectedImage([]);
          setSelectedEnglishPdf([]);
          setSelectedFrenchPdf([]);
          setFileUrl(null);
          setFileUrlEnglishPdf(null);
          setFileUrlFrenchPdf(null);
          setEditorDescription("");
          setLoadingSubmitButton(false);
          getlaws();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
          }            
          if (response && response.status === 500) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
          }
          setLoadingSubmitButton(false);
        });
      }else{
        const lawData = new FormData();
        lawData.append('_method', 'PUT');
        lawData.append('title', inputlaws.current[0].value.trim());
        lawData.append('category_id', inputlaws.current[1].value.trim());
        lawData.append('description', editorDescription);
        if (selectedImage) {
          lawData.append('img', selectedImage);          
        }
        if (selectedEnglishPdf) {
          lawData.append('pdf_english', selectedEnglishPdf);       
        } 
        if (selectedEnglishPdf) {
          lawData.append('pdf_french', selectedFrenchPdf);   
        }                

        await axiosClient.post(`/law/${selectedLawId}`,lawData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {          
          setSucces("Law updated success !!");
          setErrors(errors);          
          setTimeout(() => { setSucces('');}, 3000);
          setLoadingSubmitButton(false);
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Law updated success',showConfirmButton: true});
          getlaws();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
          }            
          if (response && response.status === 500) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
          }
          setLoadingSubmitButton(false);
        });
      }
    } else{
      setErrors(errors);
      setLoadingSubmitButton(false);
    } 
  }

  useEffect(() => {
    getlaws() && getcategories();
  }, []);

  const getcategories = async () => {
    setLoadingCategory(true);
    axiosClient.get('/categories').then( ({data})=> {
      setcategories(data.data);
      setLoadingCategory(false);
    }).catch(err => {
      console.log(err);
      setLoadingCategory(false);
    });
  };

  const getlaws = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/laws').then( ({data})=> {
      setlaws(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const handleDelete = async(id) => {
    Swal.fire({
      title: 'Deletion', text: 'do you really want to delete this law?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'confirm', cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('_method', 'DELETE');
        axiosClient.post(`/law/${id}`,formData).then( () => {
          Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'Law deleted successfully',showConfirmButton: true});
          getlaws()
        }).catch(err => {
          const response = err.response;
          if (response.data.message) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.message}` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
          }
        });
      }
    });
  };

  const [searchTerm, setSearchTerm] = useState('');

  const [filteredLaws, setFilteredLaws] = useState([]);

  useEffect(() => {
    const filtered = laws.filter((law) => {
      const searchString = `${law.law_title.toLowerCase()} ${law.category.category_title.toLowerCase()}`;
      return searchString.includes(searchTerm.toLowerCase());
    });
    setFilteredLaws(filtered);
  }, [laws, searchTerm]);

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Laws</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0">Latest Laws</h5>
                    <div class="card-body" style={{ float :"right" }}>
                        <h5 class="card-title mb-0" style={{float:"right"}}>
                          <Link onClick={() => handleModalOpen("")} title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                        </h5><br/><br/>                       
                        <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." class="form-control"/>                      
                    </div>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        {/* <th>Description</th> */}
                        <th>Category</th>
                        <th>En Pdf</th>
                        <th>Fr Pdf</th>
                        <th class="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(7,8)}</>:
                        <>
                            {filteredLaws && filteredLaws.map((law, index) => {                                                                              
                                const descendingIndex = laws.length - index;

                                let lawDescription = law.law_description;
                                if (lawDescription.length > 15 ) {
                                  lawDescription = lawDescription.substr(0, 15)+"..";
                                }
                                return (
                                    <>
                                        <tr>
                                            <td>{descendingIndex}</td>
                                            <td class="d-xl-table-cell">
                                                <Link to={law.law_img} target="_blank">
                                                    <img src={law.law_img} style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                                                </Link>
                                            </td>                                              
                                            <td>{law.law_title}</td>
                                            {/* <td title={law.law_description}>{lawDescription}</td> */}
                                            {/* <td title={law.law_description.replace(/<[^>]+>/g, '')} dangerouslySetInnerHTML={{ __html: lawDescription }}></td> */}
                                            <td>{law.category.category_title}</td>
                                            <td>
                                                <Link to={`/law/${law.law_id}/Ve`}>
                                                    <span class={`badge bg-secondary`}><i class="fa fa-eye"></i>See</span>
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/law/${law.law_id}/Vf`}>
                                                    <span class={`badge bg-danger`}><i class="fa fa-eye"></i>See</span>
                                                </Link>
                                            </td>
                                            <td class="d-md-table-cell">
                                                <a onClick={() => handleModalOpen(law.law_id)} className="text-primary">
                                                    Update
                                                </a>
                                                <a onClick={() => handleDelete(law.law_id)} className="text-danger" style={{marginLeft:"20px"}}>
                                                    Delete 
                                                </a>                                                                           
                                            </td>
                                        </tr>                                
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>
                  <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>
                        {selectedLawId ? "Update Law" : "Add Law"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form ref={formRef} encType="multipart/form-data">
                          {success ? <div class="alert alert-success alert-dismissible fade show" role="alert">
                              { success }
                          </div>: null}  
                          {errors.error ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
                              { errors.error }
                          </div>: null}
                          {errors.error_img ? <div class="alert alert-danger alert-dismissible fade show" role="alert">
                              { errors.error_img }
                          </div>: null}                        
                          <div class="card">
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Title*</h5><br/>
                                  <input type="text" ref={addInputsLaw} class="form-control" defaultValue={selectedLawId ? datalaw.law_title : ""}/>
                                  {errors.law_title ? <><span className="text-danger">{errors.law_title}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Description*</h5><br/>
                                  <ReactQuill value={editorDescription} onChange={handleEditoroDescriptionChange} style={editorStyle} modules={modules} formats={formats} />
                                  {errors.law_description ? <><span className="text-danger">{errors.law_description}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Image*</h5><br/>
                                  <input type="file" onChange={handleImageUpload} class="form-control"/>
                                  {errors.law_img ? <><span className="text-danger">{errors.law_img}</span><br/></> : null}
                              </div>  
                              <div class="card-header">
                                  <h5 class="card-title mb-0">English Pdf*</h5><br/>
                                  <input type="file" onChange={handleEnglishPdfUpload} class="form-control"/>
                                  {errors.law_englishpdf ? <><span className="text-danger">{errors.law_englishpdf}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">French Pdf*</h5><br/>
                                  <input type="file" onChange={handleFrenchPdfUpload} class="form-control"/>
                                  {errors.law_frenchpdf ? <><span className="text-danger">{errors.law_frenchpdf}</span><br/></> : null}
                              </div>     
                              <div class="card-header">
                                <h5 class="card-title mb-0">Category*</h5><br/>
                                <select class="form-control" disabled={loadingcategory ? 'disabled' : ''} ref={addInputsLaw} style={{ padding : "6px" }}>
                                  <option selected></option>
                                  {categories && categories.map((category) => (
                                    <>
                                      <option selected={datalaw.law_category_id == category.category_id ? 'selected' : ''} value={category.category_id}>{category.category_title}</option>
                                    </>
                                  ))}
                                </select>
                              </div>                                                                                                              
                          </div>
                          <div class="d-grid">
                              <button type="submit" class="btn btn-primary" onClick={loadingsubmitbutton ? null : addUpdateLaw}>
                                  {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                              </button>
                          </div> 
                      </form>
                    </Modal.Body>
                  </Modal>                                   
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
