import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import iconuser from '../assets/img/user-128.png'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import { AuthContext } from "../context/AuthContext";
import { Modal } from "react-bootstrap";

export default function Partner() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const { currentUser, token } = useContext(AuthContext);

  const [users, setUsers] = useState([]);

  const [getcodepromos, setGetCodepromos] = useState([]);

  const [codepromos, setCodepromos] = useState([]);

  const [getuser, setGetUser] = useState({});

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  const [loadingcodepromo, setLoadingCodepromo] = useState(false);

  useEffect(() => {
    getUsers() && getCodepromos();
  }, []);

  const getUsers = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/partners').then( ({data})=> {
      setUsers(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const getCodepromos = async () => {
    setLoadingCodepromo(true);
    axiosClient.get('/codepromos').then( ({data})=> {
      setCodepromos(data.data);
      setLoadingCodepromo(false);
    }).catch(err => {
      setLoadingCodepromo(false);
    });
  };

  const ChangeStateUser  = async(id,user_state) => {
    Swal.fire({
      title: 'Choose an Operation', icon: 'question',showDenyButton: true,showCancelButton: true, confirmButtonColor: '#10518E',confirmButtonText: 'Activer',denyButtonText: `Desactiver`, cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(user_state !== "asset")
        {
          let state = "asset";
          const formData = new FormData();
          formData.append('_method', 'PUT');
          await axiosClient.post(`/user/edit-state/${id}/${state}`,formData).then(async ({data})  => {
            getUsers();
            Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'This Partner has been activated',showConfirmButton: true})
          }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
              Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
            }
          });
        }
        else{
          Swal.fire({position: 'top-right',icon: 'warning',title: 'Information',text: 'This Partner is already active',showConfirmButton: true,confirmButtonColor: '#10518E',})
        }
      }
      else if (result.isDenied) {
        if(user_state !== "idle")
        {
          let state = "idle";
          const formData = new FormData();
          formData.append('_method', 'PUT');
          await axiosClient.post(`/user/edit-state/${id}/${state}`,formData).then(async ({data})  => {
            getUsers();
            Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'This Partner has been deactivated',showConfirmButton: true})
          }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
              Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
            }
          });
        }
        else{
          Swal.fire({position: 'top-right',icon: 'warning',title: 'Information',text: 'This Partner is already inactive',showConfirmButton: true,confirmButtonColor: '#10518E',})
        }
      }
    });
  };

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = async (user) => {
    setGetCodepromos(user.codepromos);
    setGetUser(user);
    setShowModal(true);
  }

  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");

  const AddCodePromoToUser = async(event) => {
    event.preventDefault();
    if (selectedValue == '') {
      Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `Verifier les champs` ,showConfirmButton: true,confirmButtonColor: '#10518E'})
      return;
    }
    setLoadingSubmitButton(true);
    await axiosClient.post('/user-codepromo/add',{user_id : getuser.user_id, codepromo_id : parseInt(selectedValue) }).then(({data})  => {
      setLoadingSubmitButton(false);      
      handleModalClose();
      getUsers();
      Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'CodePromo added successfully to User',showConfirmButton: true,confirmButtonColor: '#10518E'});
    }).catch(err => {
      const response = err.response;
      setLoadingSubmitButton(false); 
      if (response && response.status === 422) {
        Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `Verifier les champs` ,showConfirmButton: true,confirmButtonColor: '#10518E'})
      }
      else if (response && response.status === 500) {
        Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `Ce partenaire est deja lié à ce codepromo` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
      }        
      else{
        Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `Verifier votre Connexion Internet` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
      }
      setLoadingSubmitButton(false);
    })    
  }

  const DeleteUserCodePromo = async(id) => { 
    Swal.fire({
      title: 'Suppression', text: 'Voulez-vous supprimer ce Codepromo à ce partenaire ?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'Supprimer', cancelButtonText: 'Fermer'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const datausercodepromo = { user_id : getuser.user_id, codepromo_id : id }
        axiosClient.post(`/user-codepromo/delete`,datausercodepromo).then( () => {
          handleModalClose();
          Swal.fire({position: 'top-right',icon: 'success',title: 'Succès!',text: 'Codepromo supprimée à ce partenaire avec succès',showConfirmButton: true,confirmButtonColor: '#10518E'});
          getUsers();
        }).catch(err => {
          const response = err.response;
          if (response.data.error) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.error}` ,showConfirmButton: true,confirmButtonColor: '#10518E'})
          }else{
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: "une errreur s'est produite lors de l'execution, Verifier votre Connexion Internet" ,showConfirmButton: true,confirmButtonColor: '#10518E'})
          }
        });
      }
    });
  };

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Partners</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0" style={{float:"right"}}>
                        <Link to="/add-partner" title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                    </h5>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th class="d-xl-table-cell">Image</th>
                        <th>Name</th>
                        <th>State</th>                        
                        <th>Email</th>
                        <th class="d-md-table-cell">Action 1</th>
                        <th class="d-md-table-cell">Action 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(7,7)}</>:
                        <>
                            {users && users.map((user, index) => { 
                                let classState = "";
                                let contentState = "";
                                if (user.user_state === "asset") {classState = "bg-primary";contentState = "Asset";} 
                                else{ classState = "bg-danger";contentState = "Idle";}                                                                                                             
                                const descendingIndex = users.length - index;
                                return (
                                    <>
                                        { user.user_id != currentUser.id ?
                                            <tr>
                                                <td>{descendingIndex}</td>
                                                <td className="d-md-table-cell">
                                                    <Link to={!user.user_img || user.user_img == "" || user.user_img == null ? iconuser : user.user_img}>
                                                        <img src={!user.user_img || user.user_img == "" || user.user_img == null ? iconuser : user.user_img} style={{ height: "40px", width: "40px", borderRadius: "10px" }} /> 
                                                    </Link>
                                                </td>                                             
                                                <td>{user.user_name}</td>
                                                <td><span class={`badge ${classState}`}>{contentState}</span></td>                                                
                                                <td>{user.user_email}</td>
                                                <td>
                                                  <a onClick={() => ChangeStateUser(user.user_id,user.user_state)} className="text-warning">
                                                    Act / Des 
                                                  </a>                            
                                                </td>
                                                <td>
                                                  <a onClick={() => handleModalOpen(user)} className="text-primary">
                                                    Codepromos
                                                  </a>                            
                                                </td>                                                
                                            </tr>
                                        : null }
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>                
                </div>
                <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>
                        Ajouter un CodePromo à {getuser.user_name}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form  encType="multipart/form-data">                     
                        <div class="card">
                          <div class="card-header">
                            <h5 class="card-title mb-0">Selectionner*</h5><br/>
                            <select onChange={(event) => setSelectedValue(event.target.value)} class="form-control" disabled={loadingcodepromo ? 'disabled' : ''} style={{ padding : "8px" }}>
                              <option selected></option>
                              {codepromos && codepromos.map((codepromo) => (
                                <>
                                  <option value={codepromo.codepromo_id}>{codepromo.codepromo_name+ ' - '+codepromo.codepromo_pourcent+'%'}</option>
                                </>
                              ))}
                            </select>
                          </div>     
                          <div class="card-header">
                            <button type="submit" onClick={loadingsubmitbutton ? null : AddCodePromoToUser} class="btn btn-primary">
                              {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                            </button>
                          </div>                                                                                                    
                        </div>
                        <div class="card-header">
                          <div style={{ overflowY : "scroll", scrollBehavior: "inherit",height:"30vh",marginLeft:"auto",marginRight:"auto" }}><br /><br />
                            <table className="table table-hover my-0">
                              <thead>
                                <tr>
                                  <th style={{ borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px" }}>#</th>
                                  <th>Nom</th>
                                  <th>Pourcent</th>
                                  <th class="d-md-table-cell">Action</th>
                                </tr>                
                              </thead>
                              <tbody>
                              {getcodepromos && getcodepromos.map((codepromo,index) => {                               
                                  return (
                                    <tr key={index}>
                                      <td>{codepromo.codepromo_id}</td>
                                      <td>{codepromo.codepromo_name}</td>
                                      <td>{codepromo.codepromo_pourcent}</td>
                                      <td class="d-md-table-cell">
                                        <a className="text-danger" onClick={() => DeleteUserCodePromo(codepromo.codepromo_id)} style={{marginLeft:"20px"}}>
                                          Delete 
                                        </a>                                                                           
                                      </td>                                      
                                    </tr>
                                  );
                              })} 
                              </tbody>
                            </table> 
                          </div>                               
                        </div> 
                      </form>
                    </Modal.Body>
                  </Modal>                
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

