import React, { useRef, useState } from 'react'
import "../assets/css/app.css"
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../axios-client';
import Swal from 'sweetalert2';

export default function SignUp() {
    const inputs = useRef([]);
    const [validationerror , setValidationError] = useState("");
    const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);
  
    const formRef = useRef();
  
    const addInputs = el => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el)
      }
    }
  
    const isValidEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
  
    const handleSignUp = async (event) =>{
      event.preventDefault();
      const errors = {};

      if (inputs.current[0].value.trim() === '') {
        errors.name = 'Name is required';
      }      
  
      if (inputs.current[1].value.trim() === '') {
        errors.email = 'Email is required';
      } else if (!isValidEmail(inputs.current[1].value)) {
        errors.email = 'Email address format is not valid';
      }
      
      if (inputs.current[2].value.trim() === '') {
        errors.password = 'Password is required';
      }

      if (inputs.current[3].value.trim() === '') {
        errors.phone = 'Phone number is required';
      }      
  
      if (Object.keys(errors).length === 0) {
        setLoadingSubmitButton(true);
        const datauser = {
          name : inputs.current[0].value.trim(),          
          email : inputs.current[1].value.trim(),
          password : inputs.current[2].value.trim(),
          phone : inputs.current[3].value.trim(),
          state : "asset",
          type : "sub_admin",
          delay : "2027-01-02",
        }
        await axiosClient.post('/signup',datauser).then(({data})  => {
          setValidationError(errors);
          Swal.fire({position: 'Center',icon: 'success',title: 'Success!',text: 'User added successfully',showConfirmButton: true});
          formRef.current.reset();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            if (response.data.errors) {
              if (response.data.errors.email) {
                errors.email = response.data.errors.email;
                setValidationError(errors);
              }
            }
          }
        });
        setLoadingSubmitButton(false);
      }else{
        setValidationError(errors);
      }
    }

  return (
    <main class="d-flex w-100">
      <div class="container d-flex flex-column">
        <div class="row vh-100">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div class="d-table-cell align-middle">
              <div class="text-center mt-4">
                <h1 class="h2">Get started</h1>
                <p class="lead">
                    Increase management possible with assistance from others
                </p>
              </div>

              <div class="card">
                <div class="card-body">
                  <div class="m-sm-3">
                    <form ref={formRef}>
                      <div class="mb-3">
                        <label class="form-label">Name</label>
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          ref={addInputs}
                          placeholder="Enter your name"
                        />
                        {validationerror.name && <span className="text-danger">{validationerror.name}</span>}
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Email</label>
                        <input
                          class="form-control form-control-lg"
                          type="email"
                          ref={addInputs}
                          placeholder="Enter your email"
                        />
                        {validationerror.email && <span className="text-danger">{validationerror.email}</span>}
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Password</label>
                        <input
                          class="form-control form-control-lg"
                          type="password"
                          ref={addInputs}
                          placeholder="Enter password"
                        />
                        {validationerror.password && <span className="text-danger">{validationerror.password}</span>}
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Phone</label>
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          ref={addInputs}
                          placeholder="Enter phone number"
                        />
                        {validationerror.phone && <span className="text-danger">{validationerror.phone}</span>}
                      </div>                      
                      <div class="d-grid gap-2 mt-3">
                        <button onClick={loadingsubmitbutton ? null : handleSignUp} class="btn btn-lg btn-primary">
                          {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="text-center mb-3">
                Back to <Link to="/dashboard">Users Gestion</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
