import React from 'react';
import Empty from '../assets/img/empty.jpg'

const EmptyFetch = ({ title }) => {
  return (
    <div style={{ width: "100%", alignItems: "center" }}>
      <p style={{ textAlign: "center", alignItems: "center" }}>
        <img src={Empty} alt="Empty" style={{ height: "350px" }} />
      </p><br />
      <p style={{ fontSize: "24px", textAlign: "center", alignItems: "center" }}>
        {title}
      </p><br/><br/>
    </div>
  );
};

export default EmptyFetch;