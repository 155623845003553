import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default function Sidebar({ style }) {

  const location = useLocation();

  const isActiveMenuItem = (paths) => {
    return paths.some((path) => location.pathname.includes(path));
  };

  const { currentUser, token } = useContext(AuthContext);

  return (
    <nav id="sidebar" class={style}>
      <div class="sidebar-content js-simplebar" style={{ height : "100px" }}>
        <a class="sidebar-brand">
          <span class="align-middle">Managment Esquire Admin</span>
        </a>

        <ul class="sidebar-nav">

          {currentUser.type == "admin" ?
          <>

          <li class={isActiveMenuItem(["/dashboard"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/dashboard">
              <i class="align-middle" data-feather="sliders"></i>
              <span class="align-middle">Dashboard</span>
            </Link>
          </li>          
          <li class="sidebar-header">Types</li>

          <li class={isActiveMenuItem(["/categories"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/categories">
              <i class="align-middle" data-feather="bar-chart-2"></i>
              <span class="align-middle">Categories</span>
            </Link>
          </li>            

          <li class="sidebar-header">Rules</li>

          <li class={isActiveMenuItem(["/laws","/law"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/laws">
              <i class="align-middle" data-feather="bar-chart-2"></i>
              <span class="align-middle">Laws</span>
            </Link>
          </li>           

          <li class="sidebar-header">News</li>

          <li class={isActiveMenuItem(["/posts"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/posts">
              <i class="align-middle" data-feather="bar-chart-2"></i>
              <span class="align-middle">Posts</span>
            </Link>
          </li>      

          <li class="sidebar-header">Promo</li>

          <li class={isActiveMenuItem(["/codepromos"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/codepromos">
              <i class="align-middle" data-feather="bar-chart-2"></i>
              <span class="align-middle">Codepromos</span>
            </Link>
          </li>   

          </> : null }      

          <li class="sidebar-header">Pay</li>

          <li class={isActiveMenuItem(["/subscriptions"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/subscriptions">
              <i class="align-middle" data-feather="bar-chart-2"></i>
              <span class="align-middle">Subscriptions</span>
            </Link>
          </li>                         

          <li class="sidebar-header">Settings</li>

          <li class={isActiveMenuItem(["/profile"])? "sidebar-item active": "sidebar-item"}>
            <Link class="sidebar-link" to="/profile">
              <i class="align-middle" data-feather="user"></i>
              <span class="align-middle">Profile</span>
            </Link>
          </li>     
        </ul>
      </div>
    </nav>
  );
}
