import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EmptyFetch from "../components/EmptyFetch";
import iconuser from '../assets/img/user-128.png'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import { AuthContext } from "../context/AuthContext";
import { RefreshCw } from "lucide-react";

export default function Subscription() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const { currentUser, token } = useContext(AuthContext);

  const [subscriptions, setSubscriptions] = useState([]);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  useEffect(() => {
    getSubscriptions('','');
  }, []);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();

  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  const [startDate, setStartDate] = useState(formattedDate);

  const [endDate, setEndDate] = useState(formattedDate);

  const [message, setMessage] = useState('');

  let amountNet = 0;

  let suplusAmount = 0;

  const [errorConnection, setErrorConnection] = useState(false);

  const getSubscriptions = async (startDate,endDate) => {
    if (startDate === '' || endDate === '') {
      startDate = formattedDate;
      endDate = formattedDate;
    }
    setLoadingSkeletonButton(true);
    let data = {start_date : startDate, end_date : endDate,user_id : currentUser.id};
    await axiosClient.post('/all-subscriptions-toUser',data).then(({data})  => {
      setSubscriptions(data.data);
      setLoadingSkeletonButton(false);
      setErrorConnection(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
      setErrorConnection(true);
    });
  };

  const handleFetchSubscriptionsDate = async(e) => {
    e.preventDefault();
    if (startDate === '') {
      return;
    } 
    if (endDate === '') {
      return;
    }

    let message = startDate + ' à ' + endDate;
    if (startDate == formattedDate &&  endDate == formattedDate) {
      setMessage('');
      setStartDate(startDate);
      setEndDate(endDate);
    }else{
      setMessage(message);
      setStartDate(startDate);
      setEndDate(endDate);
    }
    getSubscriptions(startDate,endDate)
  }

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Subscriptions</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <div style={{ display:"flex", width:"100%",alignItems:"center",marginTop:"4px" }}>
                      <div style={{ width:"50%" }}>
                        <form action="" method="post">
                            Du : <input type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)}/> Au : <input type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)}/>
                            <button onClick={loadingskeletonbutton ? null : handleFetchSubscriptionsDate} type="submit" style={{ width:"40px",padding:"2px",cursor:"pointer" }}>
                              OK
                            </button>
                        </form> 
                      </div>
                      <div style={{ width:"50%" }}>
                        <p style={{ float:"right",marginTop:"-10px" }}>
                          <span>
                            <button title='Actualiser' onClick={(e) => { e.preventDefault(); getSubscriptions(startDate,endDate); }} style={{ padding:"4px", cursor:"pointer" }}>
                              <span class="text-primary txt-24"> {message === '' ? 'Aujourd\'hui' : message } </span> <RefreshCw size={23} />
                            </button>
                          </span>                                            
                        </p>                
                      </div>               
                    </div>
                  </div>
                  {loadingskeletonbutton ? 
                  <div style={{ width:"40px",alignItems:"center", textAlign:"center",alignContent:"center" }}> 
                    <p style={{ textAlign:"center" }}> 
                      <i class="fa fa-refresh fa-spin" style={{ fontSize:"2rem",textAlign:"center" }}></i> 
                    </p> 
                  </div> :
                  <>
                    {subscriptions.length > 0 ?
                    <table class="table table-hover my-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th class="d-xl-table-cell">Image</th>
                          <th>Name</th>
                          <th>Amount</th>                        
                          <th>Delay</th>
                          <th>Net Amount</th>
                          <th>Codepromo</th>
                          <th>Suplus</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                          <>
                              {subscriptions && subscriptions.map((subscription, index) => {                                                                                                         
                                  const descendingIndex = subscriptions.length - index;
                                  amountNet = amountNet+subscription.subscriptionAmountNet;
                                  suplusAmount = suplusAmount+(subscription.subscription_amount-subscription.subscriptionAmountNet);
                                  return (
                                      <>
                                        <tr>
                                            <td>{descendingIndex}</td>
                                            <td className="d-md-table-cell">
                                              <Link target="_blank" to={!subscription.user.user_img || subscription.user.user_img == "" || subscription.user.user_img == null ? iconuser : subscription.user.user_img}>
                                                <img src={!subscription.user.user_img || subscription.user.user_img == "" || subscription.user.user_img == null ? iconuser : subscription.user.user_img} style={{ height: "40px", width: "40px", borderRadius: "10px" }} /> 
                                              </Link>
                                            </td>     
                                            <td>{subscription.user.user_name}</td>    
                                            <td className="text-success" style={{ fontWeight:"bold" }}>
                                              {subscription.subscription_amount} XAF
                                            </td>                                                                              
                                            <td>{subscription.subscription_date_end}</td>
                                            <td className="text-primary" style={{ fontWeight:"bold" }}>
                                              {subscription.subscriptionAmountNet} XAF
                                            </td>
                                            <td>{subscription.subscription_codopromo}</td>
                                            <td className="text-danger" style={{ fontWeight:"bold" }}>
                                              {subscription.subscription_amount-subscription.subscriptionAmountNet} XAF
                                            </td>                                            
                                            <td>{subscription.subscription_created_at}</td>
                                        </tr>
                                      </>  
                                  );
                              })} 
                              <tr>
                                <td colSpan={6} className="text-primary" style={{ fontWeight:"bold", textAlign:"right" }}>
                                  Total : {amountNet} XAF
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={8} className="text-danger" style={{ fontWeight:"bold", textAlign:"right" }}>
                                  Total : {suplusAmount} XAF
                                </td>
                              </tr>                              
                          </>                      
                      </tbody>
                    </table>
                    :
                    <EmptyFetch title={'Aucun enregistement trouvé'} />
                    // 'aaa'
                    }
                  </>
                  }              
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
